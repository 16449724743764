import $ from 'jquery';
import 'waypoints/lib/jquery.waypoints.js';

console.log("main.js is loaded.")
;(function () {
	
	// 'use strict';

	var isMobile = {
		Android: function() {
			return navigator.userAgent.match(/Android/i);
		},
			BlackBerry: function() {
			return navigator.userAgent.match(/BlackBerry/i);
		},
			iOS: function() {
			return navigator.userAgent.match(/iPhone|iPad|iPod/i);
		},
			Opera: function() {
			return navigator.userAgent.match(/Opera Mini/i);
		},
			Windows: function() {
			return navigator.userAgent.match(/IEMobile/i);
		},
			any: function() {
			return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
		}
	};

	var mobileMenuOutsideClick = function() {

		$(document).click(function (e) {
	    var container = $("#fh5co-offcanvas, .js-fh5co-nav-toggle");
	    if (!container.is(e.target) && container.has(e.target).length === 0) {

	    	if ( $('body').hasClass('offcanvas') ) {

    			$('body').removeClass('offcanvas');
    			$('.js-fh5co-nav-toggle').removeClass('active');
				
	    	}
	    
	    	
	    }
		});

	};


	var offcanvasMenu = function() {

		$('#page').prepend('<div id="fh5co-offcanvas" />');
		$('#page').prepend('<a href="#" class="js-fh5co-nav-toggle fh5co-nav-toggle fh5co-nav-white"><i></i></a>');
		var clone1 = $('.menu-1 > ul').clone();
		$('#fh5co-offcanvas').append(clone1);
		var clone2 = $('.menu-2 > ul').clone();
		$('#fh5co-offcanvas').append(clone2);

		$('#fh5co-offcanvas .has-dropdown').addClass('offcanvas-has-dropdown');
		$('#fh5co-offcanvas')
			.find('li')
			.removeClass('has-dropdown');

		// Hover dropdown menu on mobile
		$('.offcanvas-has-dropdown').mouseenter(function(){
			var $this = $(this);

			$this
				.addClass('active')
				.find('ul')
				.slideDown(500, 'easeOutExpo');				
		}).mouseleave(function(){

			var $this = $(this);
			$this
				.removeClass('active')
				.find('ul')
				.slideUp(500, 'easeOutExpo');				
		});


		$(window).resize(function(){

			if ( $('body').hasClass('offcanvas') ) {

    			$('body').removeClass('offcanvas');
    			$('.js-fh5co-nav-toggle').removeClass('active');
				
	    	}
		});
	};


	var burgerMenu = function() {

		$('body').on('click', '.js-fh5co-nav-toggle', function(event){
			var $this = $(this);


			if ( $('body').hasClass('overflow offcanvas') ) {
				$('body').removeClass('overflow offcanvas');
			} else {
				$('body').addClass('overflow offcanvas');
			}
			$this.toggleClass('active');
			event.preventDefault();

		});
	};
	

	// function contentWayPoint() {
	// 	var i = 0;
	// 	$('.animate-box').waypoint(function (direction) {

	// 		if (direction === 'down' && !$(this.element).hasClass('animated-fast')) {

	// 			i++;

	// 			$(this.element).addClass('item-animate');
	// 			setTimeout(function () {

	// 				$('body .animate-box.item-animate').each(function (k) {
	// 					var el = $(this);
	// 					setTimeout(function () {
	// 						var effect = el.data('animate-effect');
	// 						if (effect === 'fadeIn') {
	// 							el.addClass('fadeIn animated-fast');
	// 						} else if (effect === 'fadeInLeft') {
	// 							el.addClass('fadeInLeft animated-fast');
	// 						} else if (effect === 'fadeInRight') {
	// 							el.addClass('fadeInRight animated-fast');
	// 						} else {
	// 							el.addClass('fadeInUp animated-fast');
	// 						}

	// 						el.removeClass('item-animate');
	// 					}, k * 200, 'easeInOutExpo');
	// 				});

	// 			}, 100);

	// 		}

	// 	}, { offset: '85%' });
	// }


	var dropdown = function() {

		$('.has-dropdown').mouseenter(function(){

			var $this = $(this);
			$this
				.find('.dropdown')
				.css('display', 'block')
				.addClass('animated-fast fadeInUpMenu');

		}).mouseleave(function(){
			var $this = $(this);

			$this
				.find('.dropdown')
				.css('display', 'none')
				.removeClass('animated-fast fadeInUpMenu');
		});

	};


	var goToTop = function() {

		$('.js-gotop').on('click', function(event){
			
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $('html').offset().top
			}, 500, 'easeInOutExpo');
			
			return false;
		});

		$(window).scroll(function(){

			var $win = $(window);
			if ($win.scrollTop() > 200) {
				$('.js-top').addClass('active');
			} else {
				$('.js-top').removeClass('active');
			}

		});
	
	};


	// Loading page
	var loaderPage = function() {
		$(".fh5co-loader").fadeOut("slow");
	};

	var counter = function() {
		$('.js-counter').countTo({
			 formatter: function (value, options) {
	      return value.toFixed(options.decimals);
	    },
		});
	};


	// var sliderMain = function() {
		
	//   	$('#fh5co-hero .flexslider').flexslider({
	// 		animation: "fade",
	// 		slideshowSpeed: 5000,
	// 		directionNav: true,
	// 		start: function(){
	// 			setTimeout(function(){
	// 				$('.slider-text').removeClass('animated fadeInUp');
	// 				$('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
	// 			}, 500);
	// 		},
	// 		before: function(){
	// 			setTimeout(function(){
	// 				$('.slider-text').removeClass('animated fadeInUp');
	// 				$('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
	// 			}, 500);
	// 		}

	//   	});

	// };

	// var stickyFunction = function() {

	// 	var h = $('.image-content').outerHeight();

	// 	if ($(window).width() <= 992 ) {
	// 		$("#sticky_item").trigger("sticky_kit:detach");
	// 	} else {
	// 		$('.sticky-parent').removeClass('stick-detach');
	// 		$("#sticky_item").trigger("sticky_kit:detach");
	// 		$("#sticky_item").trigger("sticky_kit:unstick");
	// 	}

	// 	$(window).resize(function(){
	// 		var h = $('.image-content').outerHeight();
	// 		$('.sticky-parent').css('height', h);


	// 		if ($(window).width() <= 992 ) {
	// 			$("#sticky_item").trigger("sticky_kit:detach");
	// 		} else {
	// 			$('.sticky-parent').removeClass('stick-detach');
	// 			$("#sticky_item").trigger("sticky_kit:detach");
	// 			$("#sticky_item").trigger("sticky_kit:unstick");

	// 			$("#sticky_item").stick_in_parent();
	// 		}
			

			

	// 	});

	// 	$('.sticky-parent').css('height', h);

	// 	$("#sticky_item").stick_in_parent();

	// };

	
	$(function(){
		mobileMenuOutsideClick();
		offcanvasMenu();
		burgerMenu();
		// contentWayPoint();
		// sliderMain();
		dropdown();
		goToTop();
		loaderPage();
		// stickyFunction();
	});


}());